<template>
  <vx-card class="p-4">

        <!-- <div class="center vx-row  items-center justify-center">
          <service-card @selectService="selectCategory" class="mr-3 ml-4 vx-col lg:w-1/5 sm:w-1/4" v-for="(item, index) in options" :key="index" :service="item"></service-card>
        </div> -->
        <ul class="centerx">
      <li class="modelx">
        {{services}}
      </li>
      <li>
        <vs-checkbox v-model="services" vs-value="نصب وردپرس">نصب وردپرس</vs-checkbox>
      </li>
      <li>
        <vs-checkbox v-model="services" vs-value="نصب Nginx">نصب Nginx</vs-checkbox>
      </li>
      <li>
        <vs-checkbox v-model="services" vs-value="نصب Apache">نصب Apache</vs-checkbox>
      </li>
    </ul>
        <div class=" mt-2 mb-base " style="height:200px'">
          <label class="my-2"> توضیحات :</label>
             <quill-editor  class="mb-4" v-model="description" :options="editorOption" value="sss" />
        </div>
        <vs-button @click="createServiceRequest()">ثبت درخواست</vs-button>
  </vx-card>
</template>


<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ServiceCard from './components/ServiceCard.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    ServiceCard,
    quillEditor
  },
  data () {
    return {
      editorOption: {

        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'link', 'blockquote'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }]

          ]
        },
        placeholder: 'کمی در مورد شرایط خود بنویسید.....'
      },
      serviceData: [{}],
      description: '',
      services: [],
      options: [],
      categories: []
    }
  },
  methods: {
    validateStep1 () {
      if (this.category) {
        return true
      } else  {
        this.$vs.notify({
          text : 'لطفا نوع خدمت  را انتخاب کنید.',
          color : 'warning',
          position : 'top-center'
        })
        return false
      }
    },
    selectCategory (e) {
      this.category = e.id
      this.$refs.checkoutWizard.nextTab()
    },
    getCategory () {
      this.$http
        .get('/service/categories')
        .then((result) => {
          this.options = result.data.params
        })
        .catch((err) => {
          console.log(err.response.data.message)
        })
    },
    createServiceRequest () {
      this.$http
        .post('/service/store', {
          service_category_id: '1',
          user_description_html: this.description + this.services
        })
        .then((result) => {
          this.$swal({
            title: 'درخواست سرویس',
            text: result.data.message,
            confirmButtonText: 'لیست درخواست ها و خدمات'
          }).then(() => {
            this.$router.push('/services/list')
          })
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message, color: 'danger' })
        })
    }
  },
  created () {
    this.getCategory()
  }
}
</script>

<style scoped>
.bg-gray {
  margin-top: 15px !important;
  background-color: #f6dee5 !important;
  padding: 5px;
  border-left: 2px solid black;
}
.ql-snow {
  height: 100px !important;
}
.ql-editor{
    min-height:200px !important;
}
</style>
