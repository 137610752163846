<template>
  <vx-card
    class="p-0 grid-view-item mb-base mt-base"
  >
    <div class="">
      <!-- ITEM IMAGE -->
      <div class="justify-center" >
        <div class="flex justify-center">
          <img
            width="80"
            :src="require(`@/assets/images/solid/${setImage(service.id)}.png`)"
          />
          <!-- {{service.id}} -->
        </div>
        <div class="w-full my-4 text-center">
          <span  >{{service.name}}</span>
        </div>
      </div>
      <div class="justify-center">
        <!-- RATING & PRICE -->
        <div class="mx-auto">
            <b-button size="sm" @click="onService" class="w-full bg-h-secondary border-0" > انتخاب</b-button>
        </div>
      </div>
        <!-- {{service}} -->
    </div>
  </vx-card>
</template>
<script>
export default {
  props : ['service'],
  data () {
    return {

    }
  },
  methods : {
    setImage (id) {
      return id === 1 ? 'sys' : id === 2 ? 'devOps' : id === 3 ? 'wordpress-logo' : id === 4 ? 'seo' : id === 5 ? 'high-sell' : 'servers'
    },
    onService () {
      this.$emit('selectService', this.service)
    }
  }
}
</script>
